import axios from "axios";
// @ts-ignore
import Cookies from "js-cookie";

export const GetProfile = async () => {
    let token = Cookies.get('agent_token');

    if (!token) {
        window.location.href = '/';
        return;
    }

    let ClientOption: any = {
        headers: {
            'APIKEY': process.env.REACT_APP_API_CLIENT_KEY,
            Authorization: `Bearer ${token}`,
        }
    };

    try {
        const { data: response } = await axios.get(`${process.env.REACT_APP_API_URL}user/profile`, ClientOption);
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.status === 401) {
            Cookies.remove('agent_token');
            window.location.href = '/';
            return;
        } else {
            throw error;
        }
    }
};

// seo
import { useLocation } from "react-router";
import Seo from "./features/seo/seo";
// header
import Header from "./layouts/header/header";
// component
import Footer from "./layouts/footer/footer";
// getAddress
import { getAllAddresses } from "./features/address/address";
import { RoutesUser } from "./features/routes/userRouts";
import ClientBase from "./layouts/base/clientBase";
import AdminBase from "./layouts/base/adminBase";
import { CartProvider } from "./context/cartContext";

const App = () => {
    const location = useLocation();
    // middlewheres
    const currentPath = decodeURIComponent(location.pathname);
    const userRoutes = getAllAddresses(RoutesUser);
    const isUserRoute = userRoutes.includes(currentPath);
    // setTimeout(() => {
    //     console.clear();
    // }, 500)
    return (
        <>
            <Seo
                name="vira"
                type="webapp"
                title="آکبند | مسیر هوشمند خرید دیجیتال"
                keywords="آکبند،لپتاپ،فروشگاه،کامپیوتر،لوازم جانبی،درباره،تماس"
                description="آکبند، جایی که تکنولوژی به‌روز و اصالت کالا در اولویت است! ما در آکبند با ارائه‌ی جدیدترین تجهیزات کامپیوتری، لپ‌تاپ‌های اورجینال و قطعات حرفه‌ای، تجربه‌ای مطمئن و متفاوت از خرید آنلاین را برای شما فراهم می‌کنیم. تمام محصولات ما با ضمانت اصالت کالا، گارانتی معتبر و بهترین قیمت بازار عرضه می‌شوند. اگر به دنبال خریدی بی‌دردسر، ارسال سریع و پشتیبانی حرفه‌ای هستید، آکبند انتخابی هوشمندانه برای شماست. از لپ‌تاپ‌های گیمینگ و مهندسی گرفته تا قطعات حرفه‌ای و لوازم جانبی، همه را با خیال راحت از آکبند تهیه کنید."
            />
            <CartProvider>
                <Header />
                {isUserRoute ? (
                    <>
                        <AdminBase />
                    </>
                ) : (
                    <>
                        <ClientBase />
                    </>
                )}
                <Footer />
            </CartProvider>
        </>
    )
}

export default App;
import { useForm } from "react-hook-form";
// error
import { onError } from "../../../features/error/error";
// toast
import { toastOptions } from "../../../features/toast/toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// controller
import { useMutation } from "@tanstack/react-query";
import { SendProfile } from "../../../services/panel/profile/sendProfile";
import { GetProfile } from "../../../services/panel/profile/getProfile";
import useGetData from "../../../hook/getData";
import { useEffect } from "react";

const Profile = () => {
    const { register, handleSubmit, reset } = useForm();
    const { data: profile } = useGetData(['profile'], GetProfile)
    useEffect(() => {
        reset({
            name: profile?.name || '',
            lastName: profile?.lastName || '',
            email: profile?.email || '',
        });
    }, [profile?.email, profile?.lastName, profile?.name, reset])
    const mutation = useMutation({
        mutationFn: SendProfile,
        onSuccess: (data) => {
            toast.success("اطلاعات با موفقیت تغییر یافتند", toastOptions);
            localStorage.setItem("username", data.name + data.lastName);
            localStorage.setItem("image", data.image);
            window.open('/user/profile', '_self')
        },
        onError: (error: any) => {
            const errors = error.response?.data?.message;
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });

    const onSubmit = (data: any) => {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("lastName", data.lastName);
        formData.append("email", data.email);
        if (data.image?.[0]) {
            formData.append("image", data.image[0]);
        }
        mutation.mutate(formData);
    };

    return (
        <>
            <h6 className="fw-bold mb-0">پروفایل</h6>
            <hr />
            <form className="form mt-3" onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="row gy-4">
                    <div className="col-md-6">
                        <fieldset className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                {...register("name", { required: "فیلد نام الزامی است" })}
                            />
                            <label className="form-label">نام</label>
                        </fieldset>
                    </div>
                    <div className="col-md-6">
                        <fieldset className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                {...register("lastName", { required: "فیلد نام خانوادگی الزامی است" })}
                            />
                            <label className="form-label">نام خانوادگی</label>
                        </fieldset>
                    </div>
                    <div className="col-md-12">
                        <fieldset className="form-floating">
                            <input
                                type="email"
                                className="form-control"
                                {...register("email", { required: false })}
                            />
                            <label className="form-label">ایمیل</label>
                        </fieldset>
                    </div>
                    <div className="col-md-12">
                        <fieldset className="form-floating">
                            <input
                                type="file"
                                className="form-control"
                                {...register("image")}
                            />
                            <label className="form-label">عکس پروفایل</label>
                        </fieldset>
                    </div>
                </div>
                <button disabled={mutation.isPending} type="submit" className="btn btn-primary mt-3">
                    {mutation.isPending ? 'درحال ارسال...' : 'ویرایش'}
                </button>
            </form>
        </>
    );
};

export default Profile;
